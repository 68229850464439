.result {
    padding: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin: 20px 0;
    border-radius: 2px;
    border: 1.5px solid rgb(211, 211, 211);
    background-color: rgb(248, 248, 248);
    --result-border-color: rgb(209, 209, 209);
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 10px;
}

.result:first-of-type {
    margin-top: 0;
}

.result:hover {
    background-color: rgb(253, 253, 253);
}