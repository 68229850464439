#filterResultsInput {
    font-size: 14px;
    margin: 3px -2px 10px -2px;
    border-radius: 1px;
    padding: 5px;
    background-color: rgb(206, 206, 206);
    border: 1px solid rgb(192, 192, 192);
    color: black;
    opacity: 0.8;
    width: calc(100% + 4px);
}