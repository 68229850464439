/*layout*/

#searchResults {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.tablet #searchResults {
    display: block;
}

.miniTablet #searchResults {
    display: block;
}

.mobile #searchResults {
    display: block;
    overflow: scroll;
    height: 100%;
    width: 100vw;
    position: relative;
    padding: 0;
}

.resultsColumn {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-flow: column;
    padding-left: 15px;
    padding-top: 10px;
    overflow: hidden;
}

.tablet .resultsColumn {
    display: block;
}

.miniTablet .resultsColumn {
    display: block;
}

.mobile .resultsColumn {
    display: block;
    padding: var(--global-padding);
}

/*headers*/


main.mobile {
    flex-flow: column;
}

/*scroll overlay settings*/

.resultsColumn .os-host {
    flex-grow: 1;
}

#rightSection .os-scrollbar-handle {
    background-color: white;
    opacity: 0.3;
}