#statusWidget {
    --up-color: var(--green);
    --down-color: var(--red);
    --unstable-color: var(--orange);
}

.mobile #statusWidget {
    display: none;
}

#status {
    display: flex;
    align-items: center;
}

#statusBar {
    height: 1px;
    display: flex;
    border-radius: 1px;
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 10px;
}

#statusCircle {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 10px;
    height: 10px;
    width: 10px;
}

.colored {
    display: inline-block;
    margin: 0;
    height: 2px;
    flex-basis: 0;
    flex-grow: 1;
}

div.up {
    background-color: var(--up-color);
}

div.down {
    background-color: var(--down-color);
}

div.unstable {
    background-color: var(--unstable-color);
}

#statusWidget span {
    display: block;
    margin-top: 3px;
}

#statusWidget p {
    margin-top: 10px;
    margin-bottom: 2px;
}

span.up {
    color: var(--up-color);
}

span.down {
    color: var(--down-color);
}

span.unstable {
    color: var(--unstable-color);
}

#statusDisclaimer {
    font-size: 10px;
    opacity: 0.9;
}