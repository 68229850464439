.decision {
    --approved-color: var(--green);
    --rejected-color: var(--red);
    --enforcement-color: black;
    --open-color: var(--orange);
    
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
    margin-top: 2px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.decision.approved {
    border-bottom-color: var(--approved-color);
}

.decision.rejected {
    border-bottom-color: var(--rejected-color);
}

.decision.enforcement {
    border-bottom-color: var(--enforcement-color);
}

.decision.mixed {
    border-bottom-color: var(--open-color);
}

.decision.openApplication {
    border-bottom-color: var(--open-color);
}

.decisionType {
    font-weight: bold;
}

.approved .decisionType {
    color: var(--approved-color);
}

.rejected .decisionType {
    color: var(--rejected-color);
}

.mixed .decisionType {
    color: var(--open-color);
}

.enforcement .decisionType {
    color: var(--enforcement-color);
}

.openApplication .decisionType {
    color: var(--open-color);
}

.decisionLevelAcronym {
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
}

.decisionLevelAcronym span {
    font-weight: 600;
}

.decisionLevelAcronym .low {
    color: rgb(150, 85, 1);
}

.decisionLevelAcronym .medium {
    color: rgb(126, 125, 125);
}

.decisionLevelAcronym .high {
    color: rgb(196, 167, 2);
}