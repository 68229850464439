.sliderContainer {
    position: absolute;
    z-index: 1000;
    width: 100vw;
}

.slider {
    --sliderHeaderHeight: 42px;
    --sliderHeaderFontSize: 15px;
}

.sliderHeader {
    background-color: rgb(22, 22, 22);
    min-height: var(--sliderHeaderHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    color: rgb(235, 235, 235);
}

.sliderHeader h1 {
    font-size: var(--sliderHeaderFontSize);
    display: block;
    margin: 0;
}

.sliderIcon {
    height: 15px;
    width: 15px;
    transition: transform 0.5s 0s;
}

.active .sliderIcon {
    transform: rotate(180deg);
}

.sliderContent {
    overflow: scroll;
}