#searchForm {
    display: flex;
    padding-top: var(--header-vertical-padding);
    padding-bottom: var(--header-vertical-padding);
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    

    /*SORT OUT PADDING AS A WRAPPER CLASS*/
    margin-left: 35px;
    padding-right: 35px;
}

#planningApplicationsForm {
    --form-padding-vertical: 5px;
    --form-padding-horizontal: 20px;
    --form-font-size: 21px;
    --form-font-size-mobile: 20px;
    --form-radius: 2px;
    --form-color: rgb(155, 156, 156);
    --form-text-color: #292828;
    --form-height: 100%;
    --form-height-mobile: 50px;
    --form-max-width: 600px;

    opacity: 1;
    height: 100%;
    box-sizing: border-box;
    max-width: var(--form-max-width);
    flex-grow: 2;
}

#planningApplicationsInput {
    font-size: var(--form-font-size);
    padding: var(--form-padding-vertical) var(--form-padding-horizontal);
    border-radius: var(--form-radius);
    background-color: var(--form-color);
    color: var(--form-text-color);
    height: var(--form-height);
    opacity: 1;
    border-bottom: 1px solid var(--separator-color);
    width: 100%;
    box-sizing: border-box;
}

.tablet #planningApplicationsForm {
    max-width: 10000px;
}

.miniTablet #searchForm {
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 0;
}

.mobile #searchForm {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-flow: row;
}

.miniTablet #planningApplicationsForm {
    max-width: 10000px;
}

.mobile #planningApplicationsForm {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 10000px;
}

.mobile #planningApplicationsInput {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    font-size: var(--form-font-size-mobile);
}

#planningApplicationsInput::placeholder {
    color: var(--form-text-color);
}

#planningApplicationsInput:-internal-autofill-previewed {
    background-color: var(--form-color) !important;
}

#planningApplicationsInput:-internal-autofill-selected {
    background-color: var(--form-color) !important;
}