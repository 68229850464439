@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;

  --bcaac-theme-color: #1d3f34;
  --very-dark-grey: rgb(22, 22, 22);
  --dark-grey: rgb(26, 26, 26);
  --mid-grey: rgb(40, 40, 40);
  --light-grey: rgb(54, 54, 54);
  --very-light-grey: rgb(160, 160, 160);
  --global-padding: 10px;
  --green: rgb(67, 122, 67);
  --orange: rgb(173, 136, 67);
  --red: rgb(148, 50, 50);

  --header-height: 50px;
  --footer-height: 25px;

  --outer-margin: 20px;

  --sidebar-width: 300px;
  --sidebar-width-tablet: 300px;
  --sidebar-width-mini-tablet: 250px;

  --sidebar-padding: 10px;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  overflow: hidden;
  flex-flow: column;
}

/*layout*/

#root {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
}

/*these two ensure everything lines up all the time between 'sidebar' and 'main' on the right*/

.leftContainer {
  margin-left: var(--outer-margin);
  flex-shrink: 0;
}

button {
  outline: none;
  border: none;
}

button:focus {
  outline: none;
  outline: 1px solid rgba(255, 255, 255, 0.8);
}

::selection {
  background-color: rgba(255,255,255,0.6);
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tablet #pageFooter span, .miniTablet #pageFooter span, .mobile #pageFooter span {
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  width: 100%;
  box-sizing: border-box;
  border-style: none;
  border-width: 0;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}

input:focus {
  outline: none;
  opacity: 0.8;
}

.resultsColumn .os-host {
  height: 100%;
}

#leftSection .os-host {
  flex-grow: 1;
}

a {
  text-decoration: none;
  transition: color 0.1s ease-in-out;
}

a:hover {
  color: var(--bcaac-theme-color);
}