#pageHeader {
    --header-vertical-padding: 5px;
    --header-color: rgb(15, 19, 17);
    --header-title-color: rgb(37, 37, 37);;
    --header-opacity: 0.9;
    --header-border-color: rgb(173, 171, 171);
    --header-title-border-color: rgb(63, 62, 62);

    background-color: var(--header-color);
    opacity: var(--header-opacity);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: var(--header-height);
    /*header height set in index.css so that main can assume correct height via calc*/
    border-bottom: 1px solid var(--header-border-color);

    /*temp*/
    --cool-color-grey: rgb(15, 19, 17);
}

#headerLogoAndText {
    width: var(--sidebar-width);
    padding: 0 var(--sidebar-padding);
    height: 100%;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--header-title-border-color);
    border-right: 1px solid var(--header-title-border-color);
    background-color: var(--header-title-color);
}

.tablet #headerLogoAndText {
    width: var(--sidebar-width-tablet)
}

.miniTablet #headerLogoAndText {
    width: var(--sidebar-width-mini-tablet)
}

.mobile #headerLogoAndText {
    display: none;
}
  
#headerLogo {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

#headerText {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
  
#pageHeader h1 {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    color: rgb(255, 255, 255);
}

#headerLink {
    font-size: 11px;
    color: rgb(207, 207, 207);
    display: block;
    margin-top: 0px;
} 

.mobile #pageHeader {
    padding: 4px 20px;
    border-bottom: none;
}