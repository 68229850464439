.filterButtons {
    margin: 5px 0px;
}

.filterButtons button {
    color: white;
    background-color: rgb(90, 90, 90);
    margin-top: 0;
    width: 100%;
    flex-flow: row;
    padding: 6px 5px;
    border-radius: 2px;
    opacity: 0.9;
    transition: opacity 0.25s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

.filterButtons button:hover span {
    color: white;
}

.filterButtons button:hover {
    opacity: 1;
}

.mobile .filterButtons button {
    padding: 10px;
}

.mobile .filterButtons button span {
    font-size: 15px !important;
}

#suggestionWidget .filterButtons button span {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
}