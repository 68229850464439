.results {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 15px;   
    overflow-x: hidden;
}

.miniTablet .results {
    flex-basis: auto;
    height: auto;
}

.mobile .results {
    flex-basis: auto;
    height: auto;
    padding: 0;
}

.tablet .results {
    flex-basis: auto;
    height: auto;
}

.results span {
    display: block;
}
