.officer {
    padding-bottom: 8px;
    line-height: 1.4rem;
}

.officerName {
    font-size: 15px;
    font-weight: 500;
}
.officerEmail {
    font-size: 13px;
    margin-top: -2px;
    display: block;
}