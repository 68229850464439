.spinner {
    animation: rotation 2s infinite linear;
}

.smallIcon {
  width: 18px;
  height: 18px;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }