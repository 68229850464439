#suggestionWidget {
    box-sizing: border-box;
    border-radius: 0;
}

#suggestionWidget span {
    font-size: 11px;
}

.mobile #suggestionWidget span {
    font-size: 16px;
}

#suggestionWidget ul {
    padding-left: 0;
}

#suggestionWidget h2 {
    margin-top: 0;
    font-size: 1.2em;
}

#suggestionWidget h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.9em;
}

.mobile #suggestionWidget h3 {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.mobile #suggestionWidget {
    margin-top: 0;
    border-radius: 0;
}

.mobile #suggestionWidget .sidebarWidgetTitle {
    display: none;
}