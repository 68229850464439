.iconButtonBar {
    width: 100%;
    display: flex;
}

.iconButtonBar .iconButton {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.iconButtonBar .iconButton {
    margin-right: 20px;
}

.iconButtonBar .iconButton:last-of-type {
    margin-right: 0;
}

.iconButton {
    display: inline-flex;
    flex-flow: column;
    margin: 8px 0;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 4px;
    padding: 15px 5px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 10px;
    margin-right: 10px;
}

.iconButton:hover {
    opacity: 1;
}

.icon {
    height: 22px;
    width: auto;
}

.iconButton span {
    display: block;
    margin-top: 7px;
    font-size: 11px;
    transition: color 0.2s ease-in-out;
}

.iconButton:hover span {
    color: rgb(28, 114, 136);
}
