#sidebar {
    width: var(--sidebar-width);
    padding: var(--sidebar-padding);
    --sidebar-color: rgb(238, 238, 238);
    --sidebar-border-color: rgb(226, 226, 226);;

    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    
    height: 100%;
    box-sizing: border-box;
    background-color: var(--sidebar-color);
    border-right: 1px solid var(--sidebar-border-color);
    border-left: 1px solid var(--sidebar-border-color);
    position: relative;
}

.tablet #sidebar {
    width: var(--sidebar-width-tablet);
}

.miniTablet #sidebar {
    width: var(--sidebar-width-mini-tablet);
}

.mobile #sidebar {
    /*take full width, breakpoint*/
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0 10px;
    border: none;
}

/*sidebarwidgets*/

.sidebarWidget {
    font-size: 12px;
    padding: 15px 10px;
    border-bottom: 1px solid rgb(209, 209, 209);
}

.sidebarWidgetContent {
    margin-top: 8px;
}

.sidebarWidget:first-of-type {
    margin-top: 0;
    padding-top: 10px;
}

.mobile .sidebarWidget {
    border: none;
    padding-top: 5px;
}

.sidebarWidgetTitle {
    font-size: 14px;
    margin: 0;
}

.mobile #searchWidgets {
    margin-top: 0;
    padding-bottom: 0;
}
