/*overall layout*/

main {
    --separator-color: var(--light-grey);
    height: calc(100% - var(--header-height));
    display: flex;
    box-sizing: border-box;
}

#rightSection {
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.miniTablet #rightSection {
    padding: 5px;
}

.mobile #rightSection {
    display: block;
    flex-flow: column;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
}

/*scrollbar overlay settings */

#sidebar .os-host {
    height: 100%;
}

.mobile #sidebar .os-host {
    height: 100%;
}

.mobile #rightSection .os-host {
    flex-grow: 1;
}

.mobile .sidebarWidget {
    background-color: rgba(0,0,0,0);
}

.tablet #rightSection .os-host {
    width: 100%;
}

.miniTablet #rightSection .os-host {
    width: 100%;
}

.mobile #rightSection .os-host {
    width: 100%;
}

.overlayScrollbarsContainer {
    flex-grow: 1;
}

.overlayScrollbarsContainer .os-host {
    height: 100%;
}

.mobile main {
    flex-flow: column-reverse;
}

.maxWidth {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    flex-grow: 1;
}

.mobile .maxWidth {
    flex-grow: 1;
    overflow: scroll;
}

.mobile #rightSection {
    padding: 0 10px;
}