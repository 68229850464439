.loadMoreButton {
    padding: 10px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    background-color: rgb(199, 199, 199);
    font-size: 14px;
    width: 100%;
    opacity: 0.9;
    border: 1px solid rgb(173, 173, 173);
    margin-bottom: 20px;
    align-items: flex-start;
}