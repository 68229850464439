.resultsColumnHeader {
    padding-bottom: 10px;
}

.resultsColumnTitle {
    font-size: 16px;
    margin: 0;
}

.resultsColumnSpan {
    font-size: 11px;
    color: rgb(194, 194, 194);
    display: block;
}
