.datesSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    margin-bottom: 12px;
    margin-top: 8px;
    border-bottom: 1px solid var(--result-border-color);
    line-height: 1.4rem;
}

.datesSection > div {
    margin-right: 40px;
}

.dateDescription {
    font-size: 12px;
    display: block;
    margin-bottom: -4px;
    color: rgb(80,80,80);
}

.date {
    font-size: 15px;
}